import { DatadogLoggingService } from '@edx/frontend-logging';

/**
 * @typedef {Object} Event
 * @property {Object} view - The view information within the event.
 * @property {string} [view.url] - The URL of the view.
 * @property {string} [view.name] - The name of the view.
 * @property {string} [view.referrer] - The referrer URL of the view.
 */

/**
 * Cleans and updates the view attribute in the event object.
 * Updates the property with a path by replacing the username with '?'.
 *
 * @param {Event} event - The event object containing view information.
 */
function cleanEventView(eventString, expression = /u\/[^&]*/, replacer = 'u/?') {
  return eventString.replace(expression, replacer);
}

/**
 * Custom implementation of DatadogLoggingService to include additional
 * event cleaning logic before sending the event to Datadog.
 *
 * @extends DatadogLoggingService
 */
class CustomDatadogLoggingService extends DatadogLoggingService {

  beforeSend(event, context) {
    // Perform any common/shared logic for `beforeSend` defined within DatadogLoggingService.
    const baseBeforeSendResult = super.beforeSend(event, context);
    if (!baseBeforeSendResult) {
      // base `beforeSend` logic denotes the event should be discarded; no need to do addtl MFE-specific checks
      return false;
    }

    // clean the view url of the username
    if (/u\/[^&]*/.test(event.view.url)) {
      event.view.url = cleanEventView(event.view.url);
    }
    // clean the view name of the username
    if (/u\/[^&]*/.test(event.view.name)) {
      event.view.name = cleanEventView(event.view.name);
    }
    // occasionally the referral URL happens to also be the Profile page
    if (/u\/[^&]*/.test(event.view.referrer)) {
      event.view.referrer = cleanEventView(event.view.referrer);
    }

    return true;
  }
}

const config = {
  loggingService: CustomDatadogLoggingService,
};

export default config;
